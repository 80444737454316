@import '../../_variables.scss';

.profileImage {
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  &.goldenBorder:before {
    content: '';
    display: block;
    background-image: url('/images/common/golden_border_main.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 100%;
  }

  .box {
    cursor: pointer;
    position: relative;

    mask-image: url('/images/common/polygon-mask-main.png');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;

    @media all and (max-width: $break-point-xs) {
      flex-shrink: 0;
    }

    & > span,
    & img {
      position: relative;
      z-index: 3;
      display: block !important;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      background: url('/images/common/polygon-big-main.png');
      background-size: contain;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 50px;
      height: 50px;
      background: url(/images/common/load_spinner.png) no-repeat center;
      background-size: contain;
      animation: rotate 1.2s linear infinite;
      margin-top: -25px;
      margin-left: -25px;
      z-index: 2;
      top: 50%;
      left: 50%;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }

    .svgBox {
      width: 300px;
      height: 300px;
      position: relative;
      z-index: 3;
    }
  }

  &.w250 {
    .box {
      width: 99%;
    
      .svgBox {
        width: 250px;
        height: 250px;
      }
    }
  }

  &.w150 {
    .box {
      width: 150px;
    
      .svgBox {
        width: 150px;
        height: 150px;
      }
    }
  }

  &.w66 {
    .box {
      &:after {
        width: 20px;
        height: 20px;
        margin-top: -16px;
        margin-left: -10px;
      }
    
      .svgBox {
        width: 66px;
        height: 66px;
      }
    }
  }

  &.w53 {
    .box {
      &:after {
        width: 20px;
        height: 20px;
        margin-top: -16px;
        margin-left: -10px;
      }
    
      .svgBox {
        width: 53px;
        height: 53px;
      }
    }
  }
}