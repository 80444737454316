@import '../_variables.scss';

.homePage {
  @media all and (max-width: $break-point-xs) {
    // padding-top: 80px;
  }

  .heroBg {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  .hero {
    position: relative;
    margin-top: -40px;
    padding-top: $spacingBig;
    
    @media all and (max-width: $break-point-xs) {
      padding-top: 0;
      margin-top: -80px;
    }

    h1 {
      font-size: 32px;
      line-height: 1.3;
      color: #FFF;
      margin-bottom: 18px;
      max-width: 700px;
      margin: 20px auto;
      color: $sixthColor;
    }

    p {
      max-width: 600px;
      margin: 20px auto 0;
      color: $textLightColor;

      span {
        color: #00B6BD;
        cursor: pointer;
      }
    }

    button {
      margin: $spacingBig auto 140px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .usersSection {
    overflow: hidden;

    .title {
      color: $fifthColor;
      font-size: 32px;
      line-height: 1.3;
      margin-bottom: 18px;
      max-width: 450px;
      margin: 20px auto;
      font-family: 'Playfair Display', serif;
      font-weight: 700;

      @media all and (max-width: $break-point-xs) {
        padding: 0 30px;
      }
    }

    .usersList {
      display: flex;
      margin-bottom: 20px;
      overflow-x: scroll;
      transition: all 0.2s linear;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .userCard {
        width: 300px;
        height: 386px;
        flex-shrink: 0;
        box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.25);
        background: linear-gradient(45deg, #5C5EB8, #888AE7, #00B6BD);
        border-radius: $borderRadiusMain;
        margin: 20px;
        padding: 1px;

        .userCardContent {
          background-color: #1e1e29;
          background-image: url('/images/home/usercard_bg.jpg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top center;
          border-radius: $borderRadiusMain;
          width: 298px;
          height: 384px;
          padding: $spacingBase $spacingBase 20px;
      
          @media 
            (-webkit-min-device-pixel-ratio: 2), 
            (min-resolution: 192dpi) {
            background-image: url('/images/home/usercard_bg@2x.jpg');
          }

          .box {
            max-height: 100%;
            overflow: hidden;
          }

          .image {
            text-align: center;
            margin-bottom: $spacingMicro;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center
          }

          .name {
            font-size: $fontSizeBasePlus;
            font-weight: 600;
            margin-bottom: $spacingNano;
            cursor: pointer;

            span {
              color: #1D1D29;
              background-color: $sixthColor;
              border-radius: 40px;
              margin-left: $spacingMicro;
              font-size: 12px;
              font-weight: 600;
              padding: 3px 9px;
              position: relative;
              top: -2px;
            }

            &.gen {
              color: $sixthColor;

              span {
                background-color: $sixthColor;
              }
            }
          }

          .position {
            color: #FFF;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: $spacingNano;
          }

          .summary {
            color: $textGreyColor;
            font-size: 13px;
          }
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: relative;
        border-radius: 50%;
        background-color: $secondaryBackground;
        cursor: pointer;
        margin: 0 $spacingMicro;

        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 15.6px;
          width: 8.4px;
          background-color: #FFF;
          mask: url(/images/icons/caret-left.svg) no-repeat center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-left: -1px;
        }

        &.right:after {
          mask: url(/images/icons/caret-right.svg) no-repeat center;
          margin-left: 1px;
        }
      }
    }
  }

  .body {
    margin-top: 40px;

    @media all and (max-width: $break-point-xs) {
      & > div:nth-child(1) {
        flex-direction: column-reverse;
      }
      & > div:nth-child(3) {
        flex-direction: column-reverse;
      }
    }

    .flex {
      margin: 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      
      @media all and (max-width: $break-point-xs) {
        flex-wrap: wrap;
      }

      .block {
        width: 50%;
        text-align: center;

        @media all and (max-width: $break-point-xs) {
          width: 100%;
        }
      
        &.textblock {
          max-width: 380px;
          text-align: left;

          @media all and (max-width: $break-point-xs) {
            text-align: center;
          }

          h2 {
            color: $sixthColor;
          }
        }
      }
    }
  }

  .footer {
    background-color: $secondaryBackground;
    padding-bottom: 116px;

    .doors {
      width: 42.5%;
      margin: 0 auto -250px;
      position: relative;
      z-index: 1;
      text-align: center;

      @media all and (max-width: $break-point-xs) {
        margin-bottom: -150px;
        width: 100%;
      }
    }

    .container {
      position: relative;
      z-index: 2;

      .title {
        font-size: 32px;
        line-height: 1.3;
        color: $sixthColor;
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        text-align: center;
        margin-bottom: $spacingMicro;
      }

      p {
        text-align: center;
        max-width: 660px;
        margin: 0 auto $spacingBasePlus;

        span {
          color: $sixthColor;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $spacingBig;

        @media all and (max-width: $break-point-xs) {
          flex-wrap: wrap;
        }

        a {
          margin: 0 $spacingMicro 20px;
        }

        button {
          margin: 0 $spacingMicro 20px;

          &.transparent {
            background: transparent;
            border: 1px solid $accentColor;
          }
        }
      }

      .divider {
        margin-bottom: $spacingBig;

        @media all and (max-width: $break-point-xs) {
          margin-left: -30px;
          margin-right: -30px;
        }
      }

      .connect {
        display: flex;
        justify-content: center;
        margin-bottom: 140px;

        @media all and (max-width: $break-point-xs) {
          flex-wrap: wrap;
        }

        .text {
          width: 320px;
          flex-shrink: 0;
          margin-right: $spacingBig;

          @media all and (max-width: $break-point-xs) {
            margin-right: 0;
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
          }
        }

        button {
          min-width: 280px;
          
          @media all and (max-width: $break-point-xs) {
            min-width: calc(100vw - 60px);
            padding: 16px 48px 16px 54px;
          }
        }
      }
    }
  }
}
